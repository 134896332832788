import type { Administrator } from 'modules/core/entities/Administrator/Administrator.entity';
import type { Tenant } from 'modules/core/entities/Tenant/Tenant.entity';
import type { ReactNode } from 'react';

export interface Permissions {
  activation_history: boolean;
  administrators: boolean;
  applications: boolean;
  audit_logs: boolean;
  certificates: boolean;
  data_usage: boolean;
  devices: boolean;
  group_policies: boolean;
  groups: boolean;
  ios_group_policies: boolean;
  launchers: boolean;
  maps: boolean;
  shared_folders: boolean;
  themes: boolean;
  term_agreements: boolean;
  tracks: boolean;
  insights: boolean;
  users: boolean;
  windows_group_policies: boolean;
  sim_cards: boolean;
  android_integrations: boolean;
}

export type UserProviderProps = {
  children: ReactNode;
};

export type UserContextProps = {
  administrator: Administrator;
  tenant: Tenant;
  featureFlags: string[];
  permissions: Permissions;
};

export enum ActionType {
  UPDATE_ADMINISTRATOR_INFOS = 'UPDATE_ADMINISTRATOR_INFOS',
  UPDATE_TENANT_INFOS = 'UPDATE_TENANT_INFOS',
  UPDATE_FEATURE_FLAGS = 'UPDATE_FEATURE_FLAGS',
  UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS',
}

export type InitialState = {
  administrator: Administrator;
  tenant: Tenant;
  featureFlags: string[];
  permissions: Permissions;
};

export type ChangeAdministratorInfos = {
  type: ActionType.UPDATE_ADMINISTRATOR_INFOS;
  payload: Administrator;
};

export type UpdatePermissions = {
  payload: Permissions;
  type: ActionType.UPDATE_PERMISSIONS;
};

export type ChangeTenantInfos = {
  type: ActionType.UPDATE_TENANT_INFOS;
  payload: Tenant;
};

export type UpdateFeatureFlags = {
  type: ActionType.UPDATE_FEATURE_FLAGS;
  payload: string[];
};

export type Action = ChangeAdministratorInfos | ChangeTenantInfos | UpdateFeatureFlags | UpdatePermissions;
