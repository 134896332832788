import type { RouteObject } from 'react-router-dom';
import { EmbbededSimManagement, EmbeddedGeofence, EmbeddedGeofenceConfiguration } from '../embbeded';
import { GeofenceProvider } from '../contexts/GeofenceContext';
import { LoadingFallbackComponent } from 'modules/core/components/LoadingFallback/LoadingFallbackComponent';
import { RouteGuardComponent } from 'modules/core/components/RouteGuard/RouteGuardComponent';
import React, { Suspense } from 'react';
import { FooterComponent } from 'modules/core/components/Footer/FooterComponent';
import { UserProvider } from 'modules/core/contexts/user';

const ContactsPage = React.lazy(async () => await import('../pages/contacts/ContactsPage'));

export const EmbeddedManagementRoutes: RouteObject[] = [
  {
    path: 'geofence',
    element: <EmbeddedGeofence />,
  },
  {
    path: 'geofence-configuration',
    element: (
      <RouteGuardComponent>
        <UserProvider>
          <GeofenceProvider>
            <EmbeddedGeofenceConfiguration />
          </GeofenceProvider>
        </UserProvider>
      </RouteGuardComponent>
    ),
  },
  {
    path: 'sim-management',
    element: (
      <UserProvider>
        <EmbbededSimManagement />
      </UserProvider>
    ),
  },
  {
    path: 'contacts',
    element: (
      <Suspense fallback={<LoadingFallbackComponent />}>
        <RouteGuardComponent>
          <UserProvider>
            <ContactsPage />
            <FooterComponent />
          </UserProvider>
        </RouteGuardComponent>
      </Suspense>
    ),
  },
];
