import { ContactsLists } from 'modules/management/entities/contacts/ContactsList.entity';
import { BaseServiceInstance } from 'services/BaseServiceInstance';
import type { AddressBook, CreateSuccessResponse, GetContactsListBody } from './ServicePulsusWebDAV.contracts';

export class ServicePulsusWebDAV extends BaseServiceInstance {
  constructor() {
    super(process.env.REACT_APP_URL_WEBDAV_API!);
  }

  async getContactsList(params: GetContactsListBody): Promise<ContactsLists> {
    const { data } = await this._instance.get<ContactsLists.Server>('/users/addressbooks', {
      params,
    });
    return new ContactsLists(data);
  }

  async createContactList(file: File, adressBook: AddressBook): Promise<CreateSuccessResponse> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('address_book', JSON.stringify(adressBook));

    const { data } = await this._instance.post(`/addressbooks/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return data;
  }

  async editContactList(adressBookId: string, body: AddressBook): Promise<number[]> {
    const { data } = await this._instance.put(`/addressbooks/${adressBookId}`, body);
    return data;
  }

  async getAssociatedGroupsOfContactList(adressBookId: string): Promise<number[]> {
    const { data } = await this._instance.get(`/addressbooks/${adressBookId}/groups`);
    return data;
  }

  async downloadFile(adressBookId: string): Promise<Blob> {
    const { data } = await this._instance.get(`/addressbooks/${adressBookId}/download_vcf`, {
      responseType: 'blob',
    });
    return data;
  }

  async deleteList(adressBookId: string) {
    await this._instance.delete(`/addressbooks/${adressBookId}`);
  }
}
