import type { ReactElement } from 'react';
import { useMemo, useState } from 'react';
import type { ComponentUploadModalProps } from './UploadCsvModalComponent.contracts';
import * as S from './UploadCsvModalComponent.styles';
import axios from 'axios';
import { ServicePulsusLoki } from 'services';
import { useTypedTranslation } from 'modules/core/hooks';
import { Mixpanel } from 'utilities';
import { useUserContext } from 'modules/core/contexts/user';

export function UploadCsvModalComponent({ onClose, changeStep, handleError, emitChange, isEmbedded }: ComponentUploadModalProps): ReactElement {
  const [file, setFile] = useState<FileList | null>(null);
  const [loading, setLoading] = useState(false);

  const sampleHref = '/import_user_sample.csv';

  const { t } = useTypedTranslation<'users.addEmployee.uploadFile'>('users.addEmployee.uploadFile');
  const { administrator, tenant } = useUserContext();

  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);

  const onChange = (csvFile: FileList | null) => {
    if (csvFile?.length === 0) {
      setFile(null);

      return;
    }

    setFile(csvFile);
  };

  const handleUploadFile = async () => {
    try {
      setLoading(true);

      if (file) {
        const data = await serviceLoki.importEmployeeUsers(file[0]!);

        trackMixpanelEvent();

        if (data.data === 'Users created successfully') {
          onClose();
          emitChange && emitChange();
        }
      }
    } catch (err) {
      if (axios?.isAxiosError(err)) {
        const errorDetail = err?.response?.data?.detail || err.message;

        if (errorDetail === 'Users could not be created') {
          handleError([
            {
              color: 'danger',
              iconType: 'faceSad',
              id: '2',
              title: `${t('error_duplicate_user_file')}`,
              text: '',
            },
          ]);
        }
      } else {
        handleError([
          {
            color: 'danger',
            iconType: 'faceSad',
            id: '2',
            title: t('error_create_users'),
            text: '',
          },
        ]);
      }
    } finally {
      setLoading(false);
    }
  };

  const trackMixpanelEvent = () => {
    if (isEmbedded) {
      Mixpanel.track('usuarios-adicionou-usuarios-via-csv-menu-lateral', tenant, administrator);
      return;
    }

    Mixpanel.track('usuarios-adicionou-usuarios-via-csv', tenant, administrator);
  };

  const isInvalidFile = () => {
    if (!file) return false;

    return !file?.[0]?.type?.includes('csv');
  };

  return (
    <>
      <S.Content isEmbedded={isEmbedded}>
        <S.Title>{t('modal_title')}</S.Title>
        <S.Text>{t('modal_text')}</S.Text>

        <S.StyledCallout isEmbedded={isEmbedded} title={t('hint')} iconType="questionInCircle" />

        <S.BodyModal>
          <S.FilePicker
            initialPromptText={t('modal_label')}
            onChange={(e) => onChange(e)}
            aria-label={t('modal_label')}
            fullWidth
            display="large"
            accept=".csv"
            required
            isInvalid={isInvalidFile()}
          />
          {isInvalidFile() && <p>{t('invalid_format')}</p>}
        </S.BodyModal>
      </S.Content>

      <S.FooterModal>
        <S.ButtonLink iconType="download" href={sampleHref}>
          {t('modal_model')}
        </S.ButtonLink>

        <S.WrapperActionsButtons>
          <S.ButtonLink onClick={changeStep}>{t('modal_cancel')}</S.ButtonLink>
          <S.Button fill onClick={handleUploadFile} isLoading={loading} disabled={isInvalidFile() || file === null || loading}>
            {t('modal_confirm')}
          </S.Button>
        </S.WrapperActionsButtons>
      </S.FooterModal>
    </>
  );
}
